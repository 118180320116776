const partnersLogo = [
  {
    name: "MultiversX",
    link: "https://multiversx.com/",
    logo: require("../assets/partners/MVX.png"),
  },
  {
    name: "QoWatt",
    link: "https://qowatt.network/fr/",
    logo: require("../assets/partners/qowatt.png"),
  },
  {
    name: "Drifters",
    link: "https://www.drifters.app/",
    logo: require("../assets/partners/DRIFTERS.png"),
  },
  {
    name: "Globees",
    link: "https://globees.fr/fr/",
    logo: require("../assets/partners/GLOBEES.png"),
  },
  {
    name: "Gaupalabs",
    link: "https://www.gaupalabs.com/",
    logo: require("../assets/partners/gaupalabs.png"),
  },
  {
    name: "Lucky1000",
    link: "https://lucky1000.io/",
    logo: require("../assets/partners/LUCKY1000.png"),
  },
  {
    name: "VSWATCH",
    link: "https://vswatch.club/",
    logo: require("../assets/partners/VSWATCH.png"),
  },
  {
    name: "Dragons Arena",
    link: "https://dragonsarena.io/",
    logo: require("../assets/partners/DragonsArena.png"),
  },
  {
    name: "Demiourgos Hodlings",
    link: "https://demiourgos.holdings/",
    logo: require("../assets/partners/DEMIOURGOS.png"),
  },
  {
    name: "J-CORP",
    link: "https://play.jcorpbattleofgods.com/",
    logo: require("../assets/partners/JCORP.png"),
  },
  {
    name: "EVOLOAD",
    link: "https://evoload.co/",
    logo: require("../assets/partners/evoload-logo-light.png"),
  },
  {
    name: "Poker Now",
    link: "https://www.pokernow.club/",
    logo: require("../assets/partners/Pokernow.png"),
  },
];

export default function Partners() {
  return (
    <section className="gradient-dark md:py-32 py-16">
      <div className="container mx-auto px-8 md:px-0">
        <h2 className="mb-20 text-center text-headline-3 text-light md:text-headline-2">
          The best <span className="text-primary">partners</span> <br /> for a better project
        </h2>

        <div className="partners-image-wrapper w-full grid md:justify-center md:grid-cols-[repeat(4,minmax(0,259px))]">
          {partnersLogo.map((partner, i) => (
            <a href={partner.link} target="_blank" rel="noreferrer" className="block h-[150px] p-5">
              <img src={partner.logo} alt={partner.name} className="m-auto h-full w-[150px] object-contain" key={i} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}
