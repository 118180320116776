import { Disclosure, Transition } from "@headlessui/react";

const FAQData = [
  {
    title: "What is xCards?",
    description:
      "xCards is a unique online platform that combines elements of Poker with the versatility of NFTs. Users can participate in various Poker formats including Multi-Table Tournaments, Sit & Go, and Cash-game tables. The platform features special collections of NFTs, such as the 3 DiamondHands Collections and the Poker Leaders, which brings benefits and rewards.",
  },
  {
    title: "How do I play Poker with xCards?",
    description:
      "To play Poker with xCards, users will likely need to register on the platform and purchase chips or enter Freerolls. Poker can be played in various formats like Multi-Table Tournaments, Sit & Go, and Cash-game tables in Hold’em & Omaha styles. Users can also participate in Freerolls, which are often held to promote collaborative projects or reward the community.",
  },
  {
    title: "What are the utilities of NFT xCards?",
    description:
      "The NFT xCards in the platform can provide a range of benefits. Holders can get 1 rebuy for every Freeroll, gain access to private Freerolls, participate in a Sunday lottery to win $eGLD, earn $ROYAL with boosters, and stake a full hand from the 3 DiamondHands collections to earn rewards on the poker platform.",
  },
  {
    title: "What cryptocurrencies can I use to play Poker with xCards?",
    description:
      "As of now, players can buy chips with EGLD (MultiversX token) on the xCards platform. However, xCards has the ambition to become cross-chain and to allow buy-ins with many other cryptocurrencies in the future. So stay tuned for more options coming your way!",
  },
  {
    title: "Can I play for free on xCards?",
    description:
      "Yes, xCards hosts many Freerolls, which are free-entry poker tournaments. These are a great way to start building a bankroll from scratch. They are often used to promote collaborative projects or to reward the xCards community.",
  },
];

export default function FAQ() {
  return (
    <section className="gradient-dark py-28 md:py-32">
      <div className="container mx-auto px-8 md:px-0">
        <h2 className="mb-20 text-center text-headline-3 text-light md:text-headline-2">
          Frequently <br /> Asked <span className="text-primary">Questions</span>
        </h2>

        <div className="mx-auto mb-20 max-w-lg space-y-6">
          {FAQData.map((faq, i) => (
            <Accordion i={i} key={i} title={faq.title} description={faq.description} />
          ))}
        </div>

        <div className="text-center">
          <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
            <button className="gradient-primary-btn group rounded-full px-6 py-4 text-center transition-all duration-500 hover:px-8">
              <span className="text-secondary text-btn transition-colors duration-500 group-hover:bg-none group-hover:text-white">Contact Us</span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

const Accordion = ({ title, description, i }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className={`flex w-full items-center gap-6 border-b pb-6 transition-all ${open ? "border-orange" : "border-grey"}`}>
            <p className={`text-body-2-desktop font-medium transition-all ${open ? "text-primary" : "text-grey"}`}>0{i + 1}</p>
            <p className="flex-1 text-left text-body-2-desktop font-medium text-light">{title}</p>
            <span className={`${open ? "rotate-180 text-orange" : "text-grey"} shrink-0 transition-all`}>
              <svg width="25" height="25" fill="currentColor" viewBox="0 0 25 25">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M16.707 9.813a1 1 0 00-1.414 0L12.5 12.606 9.707 9.813a1 1 0 00-1.414 1.415l3.5 3.5a1 1 0 001.414 0l3.5-3.5a1 1 0 000-1.415z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Disclosure.Panel className="pl-12 pr-1">
              {description.split("\n").map((line, index) => (
                <p className="text-caption-1 text-grey" key={index}>
                  {line}
                  <br />
                </p>
              ))}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
