import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Concept from "./components/Concept";
import Assets from "./components/Assets";
import RoadMap from "./components/RoadMap";
import Team from "./components/Team";
import CTA from "./components/CTA";
import Partners from "./components/Partners";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import FloatingSocial from "./components/FloatingSocial";

ReactGA.initialize("G-K26VBBR7DC");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <main>
      <Navbar />
      <Home />
      <Concept />
      <Assets />
      <RoadMap />
      <Team />
      <CTA />
      <Partners />
      <FAQ />
      <Footer />
      <FloatingSocial />
    </main>
  );
}

export default App;
