import { useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const roadMapData = [
  {
    title: "Q2 2023",
    description: "",
    image: require("../assets/roadmap/1.png"),
    list: ["Launch of the cashback program", "New website", "Rebranding"],
  },
  {
    title: "Q3 2023",
    description: "",
    image: require("../assets/roadmap/2.png"),
    list: ["Launch of the Marketplace", "1st Merch Collection", "Multi-chain development "],
  },
  {
    title: "Q4 2023",
    description: "",
    image: require("../assets/roadmap/3.png"),
    list: ["Beta version of xCards Poker Tables", "DiamondHands Flop Mint", "Huge Marketing Campaign "],
  },
  {
    title: "Q1 2024",
    description: "",
    image: require("../assets/roadmap/4.png"),
    list: ["Launch of the xCards Poker Tables", "Cross-chain Partnerships", "Launch of Private Tables"],
  },
  {
    title: "Q2 2024",
    description: "",
    image: require("../assets/roadmap/5.png"),
    list: ["DiamondHands Turn & River Mint ", "Complete DiamondHands Staking", "Continued Development & Partnerships"],
  },
];

const RoadMap = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <section id="roadmap" className="gradient-secondary relative pb-16 pt-16 md:pb-20 md:pt-36">
      <div className="container mx-auto mb-12 px-8 md:mb-9 md:px-0">
        <h2 className="mb-5 text-headline-3 text-light md:mb-8 md:text-headline-2">
          An ambitious but <br /> realistic <span className="text-primary-dark">roadmap</span>
        </h2>

        <p className="text-caption-1 text-grey md:text-body-2-desktop">
          Our journey to connect Web2 and Web3 <br /> through poker is just beginning.
        </p>
      </div>

      <Swiper wrapperClass="md:mb-14 mb-12 px-8 md:px-0" ref={sliderRef} slidesPerView={"auto"} spaceBetween={32}>
        {roadMapData.map((data, i) => (
          <SwiperSlide key={i}>
            <Card data={data} i={i} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="mx-auto flex flex-col gap-y-14 px-8 items-center justify-center md:px-0">
        <div className="flex gap-x-5">
          <button onClick={handlePrev} className="p-1 text-grey transition-colors hover:text-light" aria-label="Previous">
            <svg width="24" height="25" fill="currentColor" viewBox="0 0 24 25">
              <path
                fillRule="evenodd"
                d="M9.91 7.641a1 1 0 00-1.414.057l-3.23 3.5a1 1 0 000 1.356l3.23 3.5a1 1 0 001.47-1.356l-1.682-1.822H18a1 1 0 100-2H8.284l1.682-1.822a1 1 0 00-.057-1.413z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <button onClick={handleNext} className="rotate-180 p-1 text-grey transition-colors hover:text-light" aria-label="Next">
            <svg width="24" height="25" fill="currentColor" viewBox="0 0 24 25">
              <path
                fillRule="evenodd"
                d="M9.91 7.641a1 1 0 00-1.414.057l-3.23 3.5a1 1 0 000 1.356l3.23 3.5a1 1 0 001.47-1.356l-1.682-1.822H18a1 1 0 100-2H8.284l1.682-1.822a1 1 0 00-.057-1.413z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div className="text-center">
          <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
            <button className="gradient-primary-btn group rounded-full px-6 py-4 text-center transition-all duration-500 hover:px-8">
              <span className="text-secondary text-btn transition-colors duration-500 group-hover:bg-none group-hover:text-white">Learn More</span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;

const Card = ({ data, i }) => {
  const [first, sec] = data.title.split(" ");

  return (
    <div className="min-h-[500px] md:min-h-[440px] w-[287px] overflow-hidden rounded-xl border border-[#DDDFE329] md:h-max md:w-max">
      <div className="relative h-[209px] shadow-primary">
        <img src={data.image} alt={data.title} className="h-full w-full object-cover object-center" />

        <span className="absolute -bottom-1 right-[18px] h-2 w-20 overflow-hidden rounded-full bg-[#353945]">
          <span
            className="gradient-primary block h-full rounded-full"
            style={{
              width: `${(i + 1) * 10}%`,
            }}
          ></span>
        </span>
      </div>

      <div className="p-8">
        <h3 className="mb-6 text-headline-4 text-light">
          <span className="text-primary">{first}</span> {sec}
        </h3>
        <p className="mb-4 text-hairline-small text-light">{data.description}</p>
        <ul className="ml-4 list-disc text-sm leading-[24px] text-grey">
          {data.list.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
