import xCardsIcon from "../assets/icons/xcards.svg";
import DiscordIcon from "../assets/icons/discord.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import MediumIcon from "../assets/icons/medium.svg";

export default function Footer() {
  return (
    <footer className="gradient-secondary border-t border-orange md:pt-20 pt-16">
      <div className="container mx-auto px-8 py-8 md:px-0">
        <div className="border-b border-grey pb-8">
          <div className="flex flex-col items-center justify-center">
            <img src={xCardsIcon} alt="xCards" className="mb-8" />
            <h3 className="mb-6 text-headline-4 text-light md:text-headline-3">
              Join the <span className="text-primary">Club!</span>
            </h3>
            <p className="mb-6 text-hairline-small uppercase text-grey">follow us</p>

            <div className="mb-8 flex items-center gap-2">
              <a
                className="gradient-secondary rounded-lg border border-grey px-2 py-3 transition-all duration-500 hover:scale-105"
                href="https://discord.gg/2tzGYgACMS"
                target="_blank" rel="noreferrer"
              >
                <img src={DiscordIcon} alt="Discord" />
              </a>
              <a
                className="gradient-secondary rounded-lg border border-grey px-2 py-3 transition-all duration-500 hover:scale-105"
                href="https://twitter.com/xCards_"
                target="_blank" rel="noreferrer"
              >
                <img src={TwitterIcon} alt="Twitter" />
              </a>
              <a
                className="gradient-secondary rounded-lg border border-grey px-2 py-3 transition-all duration-500 hover:scale-105"
                href="https://xcards.medium.com"
                target="_blank" rel="noreferrer"
              >
                <img src={MediumIcon} alt="Medium" />
              </a>
            </div>

            <p className="hidden text-hairline-xsmall uppercase text-grey md:block">© 2023 xCards. All Rights Reserved.</p>
          </div>
        </div>

        <div className="mt-7 space-y-4 md:text-center">
          <div>
            <p className="mb-0.5 text-hairline-xsmall uppercase text-grey md:mb-0">
              gambling can be dangerous: loss of money, family conflicts, addiction...
            </p>
            <p className="text-hairline-xsmall text-grey">xCards is a Poker platform where players can compete to earn real prizes.</p>
          </div>
          <div>
            <p className="mb-0.5 text-hairline-xsmall uppercase text-grey md:mb-0">Anyone can request to be banned from playing on Discord.</p>
            <p className="text-hairline-xsmall text-grey">xCards is not intended for use for those under 18 years of age.</p>
          </div>

          <p className="block text-hairline-xsmall uppercase text-grey md:hidden">© 2023 xCards. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}
