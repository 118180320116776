import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Logo from "../assets/Logo.svg";
import BurgerIcon from "../assets/icons/burger.svg";
import CloseIcon from "../assets/icons/close.svg";
import DiscordIcon from "../assets/icons/discord.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import MediumIcon from "../assets/icons/medium.svg";

const navData = [
  {
    name: "Concept",
    href: "#concept",
  },
  {
    name: "Feature",
    href: "#feature",
  },
  {
    name: "Assets",
    href: "#assets",
  },
  {
    name: "Roadmap",
    href: "#roadmap",
  },
  {
    name: "Team",
    href: "#team",
  },
];

const Navbar = () => {
  return (
    <nav className="gradient-secondary relative z-10 rounded-b-2xl border-b border-orange-dark">
      <div className="container mx-auto flex items-center justify-between px-8 pb-6 pt-12 md:px-0 md:py-6">
        <div className="flex items-center gap-x-16">
          <a href="/" className="shrink-0">
            <img src={Logo} alt="xCards" />
          </a>
          <div className="hidden items-center gap-x-6 lg:gap-x-10 md:flex">
            {navData.map((item) => (
              <a href={item.href} key={item.name} className="font-medium tracking-wider text-light">
                {item.name}
              </a>
            ))}
          </div>
        </div>

        <div className="hidden justify-self-end md:block">
        <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
          <button className="shadow-orange-btn special-btn">
            <span className="btn-content rounded-full px-8 py-4 leading-none text-sm font-extrabold">
              <span className="text-primary whitespace-nowrap">Play Poker</span>
            </span>
          </button>
        </a>
        </div>

        <NavbarMobile />
      </div>
    </nav>
  );
};
export default Navbar;

const NavbarMobile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [url, setUrl] = useState(window.location.href);

  return (
    <>
      <button onClick={() => setSidebarOpen(true)} className="block md:hidden">
        <img src={BurgerIcon} alt="open nav" />
      </button>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full flex-1">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gradient-to-br from-[#1B1B23] via-[#21202A] to-[#272531]">
                  <div className="mb-16 flex items-center justify-between px-8 pb-6 pt-12">
                    <img className="h-8 w-auto" src={Logo} alt="Your Company" />
                    <button onClick={() => setSidebarOpen(false)}>
                      <img src={CloseIcon} alt="Close" />
                    </button>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col">
                      {navData.map((item) => (
                        <li
                          key={item.name}
                          className={`py-2.5 pl-8 text-2xl font-semibold ${url === item.href ? "active-nav text-light" : "text-grey"}`}
                          onClick={() => {
                            setSidebarOpen(false);
                            setUrl(item.href);
                          }}
                        >
                          <a href={item.href}>{item.name}</a>
                        </li>
                      ))}
                    </ul>

                    <div className="mb-8 flex items-center justify-center gap-2">
                      <a
                        className="gradient-secondary rounded-lg border border-grey px-2 py-3 transition-all duration-500 hover:scale-105"
                        href="https://discord.gg/2tzGYgACMS"
                        target="_blank" rel="noreferrer"
                      >
                        <img src={DiscordIcon} alt="Discord" />
                      </a>
                      <a
                        className="gradient-secondary rounded-lg border border-grey px-2 py-3 transition-all duration-500 hover:scale-105"
                        href="https://twitter.com/xCards_"
                        target="_blank" rel="noreferrer"
                      >
                        <img src={TwitterIcon} alt="Twitter" />
                      </a>
                      <a
                        className="gradient-secondary rounded-lg border border-grey px-2 py-3 transition-all duration-500 hover:scale-105"
                        href="https://xcards.medium.com"
                        target="_blank" rel="noreferrer"
                      >
                        <img src={MediumIcon} alt="Medium" />
                      </a>
                    </div>
                    <div className="px-8 pb-11 pt-4">
                      <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
                        <button className="shadow-orange-btn special-btn block w-full md:inline-block">
                          <span className="btn-content rounded-full px-8 py-4 leading-none text-sm font-extrabold">
                            <span className="text-primary text-base whitespace-nowrap">Play Poker</span>
                          </span>
                        </button>
                      </a>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
