import MultiversXIcon from "../assets/icons/multiversx.svg";

export default function CTA() {
  return (
    <section className="gradient-secondary md:py-24 py-16">
      <div className="container mx-auto space-y-8 px-8 text-center md:px-0">
        <div className="space-y-4">
          <p className="text-hairline-small uppercase text-light">powered by</p>
          <img src={MultiversXIcon} alt="MultiverseX" className="mx-auto" />
        </div>

        <div className="space-y-4">
          <h2 className="text-headline-3 text-light md:text-headline-2">
            MultiversX <span className="text-primary">Blockchain</span>
          </h2>
          <p className="text-body-2-desktop text-grey">
            xCard was born on the MultiversX blockchain. The low cost, speed and scalability of its <br /> network offer the best poker experience
            ever. Its onboarding tools support the <br /> onboarding of non-blockchain users and developers. It all starts on xPortal.
          </p>
        </div>

        <div className="text-center">
          <a href="https://xport.al/referral/sg8vd5ou3j" target="_blank" rel="noreferrer">
            <button className="group rounded-full border-2 border-orange border-b-orange-dark border-r-orange-dark px-6 py-4 transition-all duration-500 hover:px-8">
              <span className="text-primary text-btn transition-all duration-500 group-hover:bg-none group-hover:text-white">Download xPortal</span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}
