import ChipImage from "../assets/concept/chip.jpg";
import CardImage from "../assets/concept/card.png";
import TournamentIcon from "../assets/concept/trophy.svg";
import CashIcon from "../assets/concept/cash.svg";
import MarketplaceIcon from "../assets/concept/marketplace.svg";
import BusinessIcon from "../assets/concept/business.svg";

const Concept = () => {
  const highlightData = [
    {
      title: "3142",
      subtitle: "Regular players",
    },
    {
      title: "20K",
      subtitle: "$EGLD played",
    },
    {
      title: "130",
      subtitle: "Tournaments held",
    },
  ];

  const platformData = [
    {
      icon: TournamentIcon,
      title: "Tournament",
      description: "Participate in our tournaments for a chance to win huge prizes. Attention, places are limited!",
    },
    {
      icon: CashIcon,
      title: "Cash Game",
      description: "Find a table at any time and compete against other players to reach the top of the leaderboard.",
    },
    {
      icon: MarketplaceIcon,
      title: "Marketplace",
      description: "Collect $ROYAL and exchange them for cryptos, NFTs, chips, merch and many other items.",
    },
    {
      icon: BusinessIcon,
      title: "Businesses",
      description: "Entertain your Web2 or Web3 community by organizing a free tournament. We'll take care of everything!",
    },
  ];

  return (
    <section className="gradient-secondary relative z-10 px-8 pt-[303px] md:px-0 md:pt-[93px]">
      <div className="container absolute -top-[303px] left-1/2 mx-auto grid items-start w-full -translate-x-1/2 gap-6 px-8 md:-top-[93px] md:grid-cols-3 md:px-0">
        {highlightData.map((data) => (
          <HighlightCard key={data.subtitle} data={data} />
        ))}
      </div>
      <div className="container mx-auto py-20 md:py-32">
        <div className="mb-16 flex flex-col justify-between md:gap-x-10 xl:gap-x-32 gap-y-12 lg:mb-36 lg:flex-row" id="concept">
          <div className="lg:max-h-[600px] max-h-[320px] max-w-[400px] sm:max-h-[450px] sm:max-w-[450px] overflow-hidden rounded-3xl border border-grey shadow-primary md:aspect-auto">
            <img src={ChipImage} alt="Chip" className="h-full w-full object-cover object-center" />
          </div>
          <div className="flex-1 space-y-16">
            <div className="space-y-8">
              <h2 className="text-headline-3 text-light md:text-headline-2">
                A <span className="text-primary">concept</span> <br /> designed by and <br /> for players
              </h2>

              <p className="text-body-2-desktop text-grey max-w-md">
                We believe that poker is the best way to bring people together at the table. Join the xCards family to play & earn cryptocurrencies.
              </p>
            </div>

            <div className="grid gap-8 md:grid-cols-2">
              <div className="space-y-5">
                <h3 className="text-body-1-desktop font-semibold text-light md:text-headline-4">Real Money</h3>
                <span className="gradient-primary inline-block h-px w-[35%]" />
                <p className="text-body-2-desktop text-grey">Play with $EGLD and soon many cryptocurrencies in a secure and benevolent ecosystem.</p>
              </div>

              <div className="space-y-5 h-[220px]">
                <h4 className="text-body-1-desktop font-semibold text-light md:text-headline-4">Play-to-Earn</h4>
                <span className="gradient-primary inline-block h-px w-[35%]" />
                <p className="text-body-2-desktop text-grey">Earn $ROYAL for free by participating in our poker games and referring your friends.</p>
              </div>
            </div>
            
            <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
              <button className="gradient-primary-btn group rounded-full px-6 py-4 shadow-primary transition-all duration-500 hover:px-8">
                <span className="text-secondary text-btn transition-colors duration-500 group-hover:bg-none group-hover:text-white">Play now</span>
              </button>
            </a>
            
          </div>
        </div>

        <div className="mb-16 flex flex-col justify-between gap-x-12 gap-y-8 lg:mb-36 lg:flex-row" id="feature">
          <div className="space-y-24 lg:w-[30%]">
            <div className="space-y-6">
              <h2 className="text-headline-3 text-light md:text-headline-2">
                The Poker <span className="text-primary">platform</span> for everyone
              </h2>
              <p className="text-body-2-desktop text-grey md:text-body-1-desktop">Whether it's for money or for fun, all players are welcome.</p>
            </div>
            <div className="mx-auto hidden aspect-square max-w-[280px] h-[300px] rotate-6 overflow-hidden rounded-3xl shadow-primary md:block">
              <img src={CardImage} alt="Card" className="h-full w-full object-cover object-center" />
            </div>
          </div>

          <div className="z-50 grid shrink gap-6 lg:w-[70%] md:grid-cols-2">
            {platformData.map((data, i) => (
              <PlatformCard key={i} data={data} />
            ))}
          </div>
        </div>
        <div className="text-center">
          <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
            <button className="gradient-primary-btn group rounded-full px-6 py-4 text-center transition-all duration-500 hover:px-8">
              <span className="text-secondary text-btn transition-colors duration-500 group-hover:bg-none group-hover:text-white">Learn More</span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};
export default Concept;

const PlatformCard = ({ data }) => {
  return (
    <div className="gradient-dark !pt-24 space-y-8 rounded-[20px] border border-orange-dark shadow-primary p-10">
      <img src={data.icon} alt="Tournament" />
      <div className="space-y-4">
        <h3 className="text-headline-4 text-light md:text-headline-3">{data.title}</h3>
        <p className="text-caption-1 text-grey">{data.description}</p>
      </div>
    </div>
  );
};

const HighlightCard = ({ data }) => {
  const [first, sec] = data.subtitle.split(" ");
  return (
    <div className="gradient-secondary h-full w-full space-y-6 rounded-2xl border border-orange-dark p-8 shadow-primary">
      <h1 className="text-center text-headline-2 text-white md:text-headline-1">{data.title}</h1>
      <p className="text-center text-hairline-large md:text-hairline-tag uppercase text-light">
        {first} <span className="text-primary">{sec}</span>
      </p>
    </div>
  );
};
