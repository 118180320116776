import { useRef, useCallback } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { Swiper, SwiperSlide } from "swiper/react";

const teamData = [
  {
    name: "Ionesco",
    role: "CO-Founder & CEO",
    image: require("../assets/team/Ionesco.webp"),
    description:
      "Ionesco an engineer-turned-entrepreneur, combined his blockchain passion and experience with MultiversX projects to create xCards, marrying poker and blockchain.",
  },
  {
    name: "Sevie",
    role: "CO-Founder & CMO",
    image: require("../assets/team/Sevie.webp"),
    description:
      "Co-Founder & CMO After studying art at the Paris Conservatory, she started her own photography business. Later on, her interest in crypto-trading and blockchain technology grew, and she became particularly passionate about MultiversX",
  },
  {
    name: "Nash",
    role: "Community Manager",
    image: require("../assets/team/nash.jpg"),
    description:
      "Nash's passion for poker and blockchain propelled him to the xCards community, where he became Community Manager through his energy and involvement. \n\n His deep understanding of blockchain and dedication to innovation make him an invaluable asset for the future of xCards.",
  },
  {
    name: "Isaacs",
    role: "Head of Engineering",
    image: require("../assets/team/Isaaccs.webp"),
    description:
      "Isaaccs is a true enthusiast of new technologies, and he developed an interest in blockchain and cryptocurrencies after completing his thesis in applied mathematics. \n\n As a data scientist, he has developed a passion for coding and is fascinated by various ecosystems, including MvX.",
  },
  {
    name: "Jason",
    role: "Blockchain Developer",
    image: require("../assets/team/Jason.webp"),
    description:
      "A Rust developer on the MultiversX blockchain, pioneered Smart Contracts in the ecosystem. \n\n He has worked on several projects, showcasing his technical expertise, and created a popular launchpad. Jason's innovation and pioneering spirit make him a valuable asset to MultiversX.",
  },
  {
    name: "SaveTheWorld",
    role: "Moderator",
    image: require("../assets/team/SaveTheWorld.webp"),
    description:
      "SaveTheWorld is an IT professional with a keen interest in NFTs, cryptocurrency, gaming, and sports. \n\n He is highly involved in the MultiversX world and serves as a moderator and collaboration manager for some of its platforms.",
  },
  {
    name: "Filou",
    role: "Collab Manager",
    image: require("../assets/team/Filou.webp"),
    description:
      "Filou, a real estate investor with a strong presence in the MvX NFT ecosystem, gradually assumed the roles of moderator and collaboration manager in several projects. \n\n His ability to bring people together and rally them around shared enthusiasm and joy of life has been instrumental in building our Discord community",
  },
  {
    name: "GRM",
    role: "General Advisor",
    image: require("../assets/team/GRM.webp"),
    description:
      "After a career in traditional finance, GRM founded several crypto and Play-To-Earn companies before becoming a content creator and Web3 Advisor.",
  },
  {
    name: "Jokke",
    role: "Poker Advisor",
    image: require("../assets/team/Jokke.webp"),
    description:
      "Passionate about poker for many years, Jokke worked for the World Poker Tour where he managed events and tournament structures before joining the Web3 space.",
  },
  {
    name: "BSCrypto",
    role: "Poker Advisor",
    image: require("../assets/team/BSCrypto.webp"),
    description:
      "Bscrypto is a Web3 builder. \n\n After 20 years in the poker industry, he advises xCards with the aim of being a major player in Web3 while offering a coherent and innovative poker product.",
  },
];

export default function Team() {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <section id="team" className="gradient-dark md:py-32 py-16">
      <div className="container mx-auto mb-12 px-8 md:mb-20 md:px-0">
        <h2 className="mb-5 text-center text-headline-3 text-light md:mb-8 md:text-headline-2">
          A complementary and <br /> experienced <span className="text-primary">team</span>
        </h2>

        <p className="text-center text-caption-1 text-grey md:text-body-2-desktop">
          The xCards team brings together a variety of profiles, <br /> from entrepreneurs to Web3 & poker experts to marketing <br /> and community
          building specialists.
        </p>
      </div>

      <Swiper wrapperClass="md:mb-12 mb-10 px-8 md:px-0 py-5" ref={sliderRef} slidesPerView={"auto"} spaceBetween={32}>
        {teamData.map((data, i) => (
          <SwiperSlide key={i}>
            <Card data={data} i={i} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="mx-auto mt-10 flex flex-col  gap-y-14 px-8 items-center justify-center md:px-0">
        <div className="flex gap-x-5">
          <button onClick={handlePrev} className="p-1 text-grey transition-colors hover:text-light" aria-label="Previous">
            <svg width="24" height="25" fill="currentColor" viewBox="0 0 24 25">
              <path
                fillRule="evenodd"
                d="M9.91 7.641a1 1 0 00-1.414.057l-3.23 3.5a1 1 0 000 1.356l3.23 3.5a1 1 0 001.47-1.356l-1.682-1.822H18a1 1 0 100-2H8.284l1.682-1.822a1 1 0 00-.057-1.413z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <button onClick={handleNext} className="rotate-180 p-1 text-grey transition-colors hover:text-light" aria-label="Next">
            <svg width="24" height="25" fill="currentColor" viewBox="0 0 24 25">
              <path
                fillRule="evenodd"
                d="M9.91 7.641a1 1 0 00-1.414.057l-3.23 3.5a1 1 0 000 1.356l3.23 3.5a1 1 0 001.47-1.356l-1.682-1.822H18a1 1 0 100-2H8.284l1.682-1.822a1 1 0 00-.057-1.413z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="text-center">
          <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
            <button className="gradient-primary-btn group rounded-full px-6 py-4 text-center transition-all duration-500 hover:px-8">
              <span className="text-secondary text-btn transition-colors duration-500 group-hover:bg-none group-hover:text-white">Learn More</span>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

const Card = ({ data }) => {
  return (
    <div>
      <Flippy flipOnHover flipDirection="horizontal" className="mb-8 h-[345px] w-[247px]">
        <FrontSide>
          <div className="rounded-full">
            <img src={data.image} alt="team" />
          </div>
        </FrontSide>
        <BackSide>
          <div className="gradient-tertiary h-full w-full p-6">
            <p className="mb-6 text-hairline-xsmall uppercase text-light">{data.role}</p>
            <p className="whitespace-pre-line text-caption-2 text-grey">{data.description}</p>
          </div>
        </BackSide>
      </Flippy>

      <p className="mb-1 text-center text-body-2-desktop text-light">{data.name}</p>
      <p className="text-center text-hairline-large uppercase text-grey">{data.role}</p>
    </div>
  );
};
