import HeroVid from "../assets/hero.mp4";
import HeroMobileVid from "../assets/hero_mobile.mp4";

const Home = () => {
  return (
    <section className="relative flex min-h-[calc(150vh-111px)] flex-col bg-cover bg-center bg-no-repeat short:pt-10 short:pb-28 py-20 md:min-h-[calc(100vh-103px)] md:items-center md:justify-center md:py-0">
      <div className="absolute top-0 z-10 h-full w-full bg-opacity-60 bg-gradient-to-br from-[#262531] to-[#1B1A22]/10" />
      <video playsInline loop autoPlay muted className="absolute top-0 block h-full w-full object-cover md:hidden">
        <source src={HeroMobileVid} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
      <video playsInline loop autoPlay muted className="absolute top-0 hidden h-full w-full object-cover md:block">
        <source src={HeroVid} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
      <div className="container relative z-20 mx-auto px-8 md:px-0">
        <h1 className="text-hero mb-5 text-white">
          Play <span className="text-primary">Poker</span> with <br /> your <span className="text-primary-dark">Cryptos</span>
        </h1>
        <p className="text-body-1 mb-10 text-light md:max-w-[40%]">
          We are building the largest poker community on MultiversX. Join it and start making money today.
        </p>
        <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
          <button className="shadow-orange-btn special-btn">
            <span className="btn-content rounded-full px-8 py-4 leading-none text-sm font-extrabold">
              <span className="text-primary whitespace-nowrap">Play Poker</span>
            </span>
          </button>
        </a>
      </div>
    </section>
  );
};
export default Home;
