import DiscordIcon from "../assets/icons/discord.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import MediumIcon from "../assets/icons/medium.svg";

const FloatingSocial = () => {
  return (
    <div className="fixed bottom-10 right-5 z-20 overflow-hidden rounded-lg border border-grey md:block">
      <div className="gradient-secondary flex flex-col place-items-center gap-y-6 px-2 py-3.5">
        <button>
          <a href="https://discord.gg/2tzGYgACMS" target="_blank" rel="noreferrer">
            <img src={DiscordIcon} alt="Discord" />
          </a>
        </button>
        <button>
          <a href="https://twitter.com/xCards_" target="_blank" rel="noreferrer">
            <img src={TwitterIcon} alt="Twitter" />
          </a>
        </button>
        <button>
          <a href="https://xcards.medium.com" target="_blank" rel="noreferrer">
            <img src={MediumIcon} alt="Medium" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default FloatingSocial;
