import React from "react";
import AdvantagesVideo from "../assets/advantages.mp4";

function Assets() {
  const assetsData = [
    {
      title: "Diamond Hand",
      description: "Build your diamond hand to fully participate in the xCards project. The more you hold, the more benefits you get at our tables.",
      image: require("../assets/assets/diamond-hand.png"),
      tags: ["Profit sharing", "Free Rebuy", "Lottery"],
      button: {
        text: "Buy Now",
        href: "https://www.frameit.gg/marketplace/DHPF-230c6b/items",
        target: "_blank",
      },
    },
    {
      title: "Poker Leaders",
      description: "Grab your seat at the xCards table to be part of the big decisions and enjoy great rewards and airdrops!",
      image: AdvantagesVideo,
      tags: ["DAO", "Passive income", "Free Merch"],
      button: {
        text: "Buy Now",
        href: "https://www.frameit.gg/marketplace/LEADERS-1bb5b4/items",
        target: "_blank",
      },
    },
    {
      title: "$ROYAL points",
      description:
        "Play and refer your friends to collect $ROYAL points. Spend them in our marketplace against cryptos, chips, NFT and even merchandise.",
      image: require("../assets/assets/royal-points.jpg"),
      tags: ["Cryptos", "Merch", "NFT"],
      button: {
        text: "Win $ROYAL",
        href: "https://discord.gg/2tzGYgACMS",
        target: "_blank",
      },
    },
  ];
  return (
    <section className="gradient-dark md:py-32 py-16" id="assets">
      <div className="container mx-auto px-8 md:px-0">
        <div className="mb-20 md:mb-28">
          <h3 className="mb-5 text-center text-[40px] font-bold leading-[48px] text-light md:text-headline-2-sm">
            <span className="text-primary">Assets</span> that link us <br className="hidden md:block" /> to our community
          </h3>
          <p className="hidden text-center text-body-2-desktop text-grey md:block">
            Our digital assets power our ecosystem through
            <br />
            their art and associated utilities.
          </p>
        </div>

        <div className="mb-20 space-y-20 md:mb-36 md:space-y-32">
          {assetsData.map((asset, i) => (
            <Row asset={asset} index={i} key={i} />
          ))}
        </div>

        <div className="text-center">
          <button className="group mx-auto block rounded-full border-2 border-orange border-b-orange-dark border-r-orange-dark px-6 py-4 duration-500 hover:px-8">
            <span className="text-primary text-btn transition-all duration-500 group-hover:bg-none group-hover:text-white"> Whitepaper Soon</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Assets;

const Row = ({ asset, index }) => {
  const isOdd = index % 2 !== 0;
  const [first, sec] = asset.title.split(" ");
  return (
    <div className={`flex flex-col-reverse items-center justify-center gap-10 lg:gap-24 ${isOdd ? "md:flex-row-reverse" : "md:flex-row"}`}>
      <div className="space-y-6 lg:w-[25%]">
        <h3 className={`text-left text-headline-3 text-light ${isOdd ? "md:text-left" : "md:text-right"}`}>
          <span className="text-primary">{first}</span> {sec}
        </h3>
        <span className="gradient-primary inline-block h-px w-full" />
        <p className={`text-left text-caption-1 text-grey ${isOdd ? "md:text-left" : "md:text-right"}`}>{asset.description}</p>

        <div className={`mt-2.5 flex flex-wrap items-center gap-4 md:mt-6 md:flex-auto ${isOdd ? "md:justify-start" : "md:justify-end"}`}>
          {asset.tags.map((tag, i) => (
            <Tag key={i} text={tag} />
          ))}
        </div>

        <div className={`!mt-12  ${isOdd ? "text-left" : "text-right"}`}>
          <a href={asset.button.href} target="_blank" rel="noreferrer">
            <button className="gradient-primary-btn group inline-block w-full rounded-full px-6 py-4 shadow-primary transition-all duration-500 hover:px-8 md:w-auto">
              <span className="text-secondary text-btn transition-colors duration-500 group-hover:bg-none group-hover:text-white">
                {asset.button.text}
              </span>
            </button>
          </a>
        </div>
      </div>

      <div
        className={`relative aspect-square h-full w-full overflow-hidden rounded-2xl border border-grey shadow-primary 
            md:max-h-[530px] md:max-w-[530px] 
        `}
      >
        {index === 1 ? (
          <video loop muted autoPlay playsInline className="absolute top-0 h-[530px] w-[530px] object-cover">
            <source src={AdvantagesVideo} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>
        ) : (
          <img src={asset.image} alt={asset.title} className="h-full w-full block object-cover" />
        )}
      </div>
    </div>
  );
};

const Tag = ({ text }) => <span className="text-primary rounded-lg border border-orange px-3 py-1 text-caption-2">{text}</span>;
